import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../views/appointments/i18n/appointmentsTable';

export const formatTextFieldObject = ({
  uid,
  fieldType,
  labelName,
  inputRequired,
  inputHidden,
  textFieldInputRestriction,
  salesforceFieldId,
  useOwnPrivacyPolicy,
  privacyPolicyUrl,
  privacyPolicyText,
}) => {
  return {
    uid,
    fieldType,
    labelName,
    salesforceFieldId,
    useOwnPrivacyPolicy,
    privacyPolicyUrl,
    privacyPolicyText,
    bookingCalendarSettingBookingCalendarCustomField: {
      bookingCalendarSettingId: null,
      bookingCalendarCustomFieldId: null,
      inputRequired,
      inputHidden,
      textFieldInputRestriction: textFieldInputRestriction !== undefined ? textFieldInputRestriction.value : null,
      salesforceFieldId
    },
  };
};

export const formatBlockOfTextObject = ({
  uid,
  fieldType,
  labelName,
  inputRequired,
  blockOfText,
}) => {
  return {
    uid: uid,
    fieldType: fieldType,
    labelName: labelName,
    bookingCalendarSettingBookingCalendarCustomField: {
      bookingCalendarSettingId: null,
      bookingCalendarCustomFieldId: null,
      inputRequired: inputRequired,
      textFieldInputRestriction: null,
    },
    bookingCalendarCustomFieldInputs: {
      label_name: labelName,
      input_text_area_value: blockOfText,
    },
  };
};

export const formatHiddenFieldObject = ({
  uid,
  fieldType,
  labelName,
  inputRequired,
  hiddenField,
  salesforceFieldId,
}) => {
  return {
    uid: uid,
    fieldType: fieldType,
    labelName: labelName,
    salesforceFieldId,
    bookingCalendarSettingBookingCalendarCustomField: {
      bookingCalendarSettingId: null,
      bookingCalendarCustomFieldId: null,
      inputRequired: inputRequired,
      textFieldInputRestriction: null,
    },
    bookingCalendarCustomFieldInputs: {
      label_name: labelName,
      input_value: hiddenField,
    },
  };
};

export const formatCheckBoxes = ({
  uid,
  fieldType,
  labelName,
  inputRequired,
  choices,
  salesforceFieldId,
  useOwnPrivacyPolicy,
  privacyPolicyUrl,
}) => ({
  uid: uid,
  fieldType,
  labelName,
  salesforceFieldId,
  useOwnPrivacyPolicy,
  privacyPolicyUrl,
  bookingCalendarSettingBookingCalendarCustomField: {
    bookingCalendarSettingId: null,
    bookingCalendarCustomFieldId: null,
    inputRequired: inputRequired,
    textFieldInputRestriction: undefined,
    salesforceFieldId
  },
  bookingCalendarMultipleChoiceOptions: choices.map((item) => ({
    bookingCalendarCustomFieldId: null,
    choiceTypeName: item.choiceTypeName,
  })),
});

export const comingToVisitTypeHeader = (isIrregular) => [
  <FormattedMessage {...messages.tableHeadCode} />,
  isIrregular ? <FormattedMessage {...messages.tableHeadPeriod} /> : <FormattedMessage {...messages.tableHeadDate} />,
  <FormattedMessage {...messages.tableHeadTitle} />,
  <FormattedMessage {...messages.tableHeadVisitor} />,
  <FormattedMessage {...messages.tableHeadAssignee} />,
  <FormattedMessage {...messages.tableHeadRoom} />,
  "",
];

export const webConferenceTypeHeader = (isIrregular) => [
  isIrregular ? <FormattedMessage {...messages.tableHeadPeriod} /> : <FormattedMessage {...messages.tableHeadDate} />,
  <FormattedMessage {...messages.tableHeadTitle} />,
  <FormattedMessage {...messages.tableHeadVisitor} />,
  <FormattedMessage {...messages.tableHeadAssignee} />,
  <FormattedMessage {...messages.tableHeadUrl} />,
  <FormattedMessage {...messages.tableHeadRoom} />,
  "",
];

export const goingToVisitTypeHeader = (isIrregular) => [
  isIrregular ? <FormattedMessage {...messages.tableHeadPeriod} /> : <FormattedMessage {...messages.tableHeadDate} />,
  <FormattedMessage {...messages.tableHeadTitle} />,
  <FormattedMessage {...messages.tableHeadVisitor} />,
  <FormattedMessage {...messages.tableHeadAssignee} />,
  <FormattedMessage {...messages.tableHeadRoom} />,
  "",
];

export const schedulingHeader = [
  "件名",
  "会議時間",
  "候補日の選択範囲",
  "候補日時を入力したお客様",
  "担当者が参加可能な候補日時の数",
  "",
  "",
];

export const schedulingFinalizeHeader = [
  "候補日程",
  "選択者"
];
