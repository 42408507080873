import { combineReducers } from "@reduxjs/toolkit";
import { generateReducers } from "./reduxUtils";
import { actions } from "./actions";
import { reducer as reduxFormReducer } from "redux-form";
import onlineReducer from './reducers/onlineReducer';
import languageReducer from './reducers/localeReducer';
import calendarsReducer from './reducers/calendarsReducer';
import templatesReducer from './reducers/templatesReducer';
import timeSlotsReducer from "./reducers/timeSlotsReducer";
import multiguestCalendarReducer from "./reducers/multiguestCalendarReducer"
import calendarEmailNotificationsReducer from "../views/notifications/reducers/calendarEmailCustomizationsReducer";

export const reducers = combineReducers({
  form: reduxFormReducer, // reducer to link redux form
  online: onlineReducer,
  locale: languageReducer,
  calendars: calendarsReducer,
  templates: templatesReducer,
  timeSlots: timeSlotsReducer,
  multiguest: multiguestCalendarReducer,
  calendarEmailCustomizations: calendarEmailNotificationsReducer,
  ...generateReducers(actions),
});
