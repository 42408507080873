import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Sidebar } from "@d-lighted/design-system";
import { isBookingTypeIrregular, isTrialExpired } from "../../utils/userUtils";
import { injectIntl } from "react-intl";
import messages from "./i18n/Layouts";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import PhoneBreakpoint from "@d-lighted/design-system/dist/components/Utils/PhoneBreakpoint";
import Header from "./Header"
import Chevron from "../icons/Chevron";
import { isMobileDevice } from "../../utils/mobileViewChecker";
import { MOBILEBREAKPOINT } from "../../constants/breakpoint";

const Main = styled.div`
  width: 100%;
  float: left;
  padding-top: ${({ isSignIn, isOnBoarding, isMobileDashboard, isTemplatePage }) => {
    if (isSignIn || isOnBoarding) return '0px';
    if (isMobileDashboard) return '16.5px';
    
    return isTemplatePage ? '108.5px' : '48.5px';
  }
};
  position: relative;
  min-height: calc(100vh - 48.5px);
  background-color: ${(props) => (props.isSignIn ? "#373a47" : "#f7f9f9")};
`;

const Content = styled.div`
  margin-left: ${(props) =>
    props.isDashboard ? "0px" : "210px"}; /* Same as the width of the sidebar */
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-left: 0px;
    min-height: 100%;
  }
`;

const TopNav = styled.div`
  background-color: #e6f4ef;
  padding: 16px;
  margin-top: ${(props) => props.trialExpired ? "68px" : "12px"};
  z-index: 1;
  display: flex;
`;

const ExpandButton = styled.div`
  margin-left: auto;
  position: relative;
  right: 6px;
  transform: ${(props) => props.displaySideBar && "rotate(180deg)"};
  transition: transform 0.3s ease;
`;

function Layout({ label, children, intl, ...rest }) {
  const isMobileView = isMobileDevice();
  if ( label === "appointments" && isMobileView ){
    label = "予約一覧"
  }
  const { uid, settings } = useParams();
  const hasSideBar = ["dashboard", "sign_in", "templates", "saml_sign_in","appointments", "onboard", "multiguest", "multiguestList", "multiguestConfirm", "multiguestFinalize", "product_info"].includes(label);
  const isSignIn = ["sign_in", "product_info"].includes(label);
  const hideRightComponent= ["saml_sign_in"].includes(label);
  const [displaySideBar, toggleSideBar] = useState(false);
  const isMobileDashboard = isMobileView && label === "dashboard";
  const isTemplatePage = [settings].includes('templates');
  const onBoarding = ["onboard"].includes(label);
  const isIrregular = isBookingTypeIrregular();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const sidebarItems = [
    {
      id: 1,
      url: `/${settings}/${uid}/edit`,
      label: intl.formatMessage({ ...messages.general }),
    },
    {
      id: 2,
      url: `/${settings}/${uid}/edit/meetings`,
      label: intl.formatMessage({ ...messages.meetings }),
      hideForIrregular: true,
    },
    {
      id: 3,
      url: `/${settings}/${uid}/edit/availability/business_hours`,
      label: intl.formatMessage({ ...messages.dateAndTimeSetting }),
      disableHighlight: true,
      subRoutes: [
        {
          id: "3-1",
          url: `/${settings}/${uid}/edit/availability/business_hours`,
          label: intl.formatMessage({ ...messages[isIrregular ? 'admissionPeriod' : 'businessDateAndTime'] }),
        },
        {
          id: "3-2",
          url: `/${settings}/${uid}/edit/availability/advanced_settings`,
          label: intl.formatMessage({
            ...messages.advancedDateAndTimeSettings,
          }),
          hideForIrregular: true,
        },
      ],
    },
    {
      id: 4,
      url: `/${settings}/${uid}/edit/booking_form/input_screen_settings`,
      label: intl.formatMessage({ ...messages.forms }),
      disableHighlight: true,
      subRoutes: [
        {
          id: "4-1",
          url: `/${settings}/${uid}/edit/booking_form/input_screen_settings`,
          label: intl.formatMessage({ ...messages.formsSettings }),
        },
        {
          id: "4-2",
          url: `/${settings}/${uid}/edit/booking_form/confirmation_message`,
          label: intl.formatMessage({ ...messages.completionMessage }),
          hideForIrregular: true,
        },
      ],
    },
    {
      id: 5,
      url: `/${settings}/${uid}/edit/chat_notifications`,
      label: intl.formatMessage({...messages.notifications}),
      disableHighlight: true,
      hideForIrregular: true,
      subRoutes: [
        {
          id: 5-1,
          url: `/${settings}/${uid}/edit/chat_notifications`,
          label: intl.formatMessage({...messages.chatNotifications}),
        },
        {
          id: 5-2,
          url: `/${settings}/${uid}/edit/email_notifications`,
          label: intl.formatMessage({...messages.emailNotifications}),
        }
      ]
    },
    {
      id: 6,
      url: `/${settings}/${uid}/integrations/salesforce`,
      label: intl.formatMessage({ ...messages.integrations }),
      disableHighlight: true,
      hideForIrregular: true,
      subRoutes: [
        {
          id: "6-1",
          url: `/${settings}/${uid}/integrations/salesforce`,
          label: intl.formatMessage({ ...messages.integration_salesforce }),
        },
      ],
    }
  ];

  const sideBarItemsForMobile = [...sidebarItems,
    {
      id: 7,
      url:`/${settings}/${uid}/appointments`,
      label: intl.formatMessage({...messages.appointmentList}),
      hasDivider:true,
      hasIcon:true,
      target:"_blank"
    }
  ];

  const filterSidebarItems = (sidebarItems) => {
    const isIrregular = isBookingTypeIrregular();
    return isIrregular ?
      sidebarItems
        .filter(item => !item.hideForIrregular)
        .map(filteredItem => {
          const updatedItem = {
            ...filteredItem
          };

          if(updatedItem.subRoutes) {
            updatedItem.subRoutes = updatedItem.subRoutes.filter(subRoute => !subRoute.hideForIrregular);
          }

          return updatedItem;
        }) :
      sidebarItems;
  };

  return (
    <>
      {!isSignIn && (
        !onBoarding &&
        <Header
          label={label}
          intl={intl}
          {...rest}
          isMobileDashboard={isMobileDashboard}
          hideRightComponent={hideRightComponent}
        />
      )}
      <Main isSignIn={isSignIn} isMobileDashboard={isMobileDashboard} isOnBoarding={onBoarding} isTemplatePage={isTemplatePage}>
        {!hasSideBar ? (
          <>
            <DesktopBreakpoint hideForDesktop>
              <div style={{ marginTop: isTrialExpired() ? '48px' : 0 }}>
                <Sidebar sidebarItems={filterSidebarItems(sidebarItems)} height={`calc(100% - ${isTrialExpired() ? '134px' : '90px' })`} />
              </div>
            </DesktopBreakpoint>
            <PhoneBreakpoint showForDesktop>
              <TopNav onClick={() => toggleSideBar(!displaySideBar)} trialExpired={isTrialExpired()}>
                {label}
                <ExpandButton displaySideBar={displaySideBar}>
                  <Chevron />
                </ExpandButton>
              </TopNav>
              {displaySideBar && (
                <Sidebar
                  p="20px 0px 0px 0px"
                  sidebarItems={filterSidebarItems(sideBarItemsForMobile)}
                  position={"absolute"}
                  mt={"-1px"}
                  height={"auto"}
                  minWidth={"100%"}
                  customStyle={{
                    itemCustomPadding: "16px",
                    subItemCustomPadding: "36px",
                    isMobileView: true,
                  }}
                />
              )}
            </PhoneBreakpoint>
          </>
        ) : (
          <></>
        )}
        <Content isDashboard={hasSideBar}>{children}</Content>
      </Main>
    </>
  );
}

Layout.defaultProps = {
  label: "",
};

const Layouts = injectIntl(Layout);
export { Layouts };
