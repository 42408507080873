import { defineMessages } from 'react-intl';

export default defineMessages({
  receptionCode: {
    id: 'views.home.modules.calendar.eventType.code',
    defaultMessage: 'Reception Code',
  },
  webConference: {
    id: 'views.home.modules.calendar.eventType.webConference',
    defaultMessage: 'Web Conference',
  },
  timeMinutes: {
    id: 'views.home.modules.calendar.timeMinutes',
    defaultMessage: ' Min',
  },
  advancedSettings: {
    id: 'views.home.modules.calendar.advancedSettings',
    defaultMessage: 'Settings',
  },
  copy: {
    id: 'views.home.modules.calendar.copy',
    defaultMessage: 'Copy',
  },
  oneTimeURL: {
    id: 'views.home.modules.calendar.oneTimeURL',
    defaultMessage: "ワンタイムURL"
  },
  fixedURL: {
    id: 'views.home.modules.calendar.fixedURL',
    defaultMessage: "固定URL"
  },
  copyInstruction: {
    id: 'views.home.modules.calendar.copyInstruction',
    defaultMessage: 'Copy instruction as well'
  },
  team: {
    id: 'views.home.modules.calendar.team',
    defaultMessage: "Team"
  },
  personal: {
    id: 'views.home.modules.calendar.personal',
    defaultMessage: 'Personal'
  },
  appointmentList: {
    id: 'views.home.modules.calendar.listAppointments',
    defaultMessage: 'Appointments'
  },
  copyBookingPage: {
    id: 'views.home.modules.calendar.copyBookingPage',
    defaultMessage: 'copy booking page'
  },
  notifyOneTimeLinkCopyError: {
    id: 'notify.home.modules.calendar.oneTimeLinkCopy.error',
    defaultMessage: 'OneTime URL couldn\'t be copied.',
  },
  notifyCopyBookingPageError: {
    id: 'notify.home.modules.calendar.copy.error',
    defaultMessage: 'Booking settings couldn\'t be copied.',
  },
  notifyCopyBookingPageLimitError: {
    id: 'notify.home.modules.calendar.copy.pageLimitError',
    defaultMessage: 'Exceeded the limit on the number of booking pages that can be created from the template. Please check with your administrator.',
  },
  multiguestSettings: {
    id: 'views.home.modules.calendar.multiguestSettings',
    defaultMessage: 'Multiguest Settings'
  },
  multiguestCalendarList: {
    id: 'views.home.modules.calendar.multiguestCalendarList',
    defaultMessage: 'Multiguest calendar list'
  },
  multiguestUrl: {
    id: 'views.home.modules.calendar.multiguestUrl',
    defaultMessage: 'Issue Multiguest URL'
  },
  listAppointmentsForIrregular: {
    id: 'views.home.modules.calendar.listAppointmentsForIrregular',
    defaultMessage: 'Staff list',
  },
  copyBookingPageForIrregular: {
    id: 'views.home.modules.calendar.copyBookingPageForIrregular',
    defaultMessage: 'Copy of registration page',
  },
  numberOfPagesLabel: {
    id: 'views.home.modules.calendar.numberOfPagesLabel',
    defaultMessage: 'No of booking pages applying the template:',
  },
  editTemplate: {
    id: 'views.home.modules.calendar.editTemplate',
    defaultMessage: 'Edit Template',
  },
  templateDetails: {
    id: 'views.home.modules.calendar.templateDetails',
    defaultMessage: 'Details'
  },
  delete: {
    id: 'views.home.modules.home.delete',
    defaultMessage: 'Delete this booking page'
  },
  deleteConfirm: {
    id: 'views.home.modules.home.deleteConfirm',
    defaultMessage: 'Are you sure you want to delete it?'
  },
});
