import React, { useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import { Field, change, reduxForm } from "redux-form";
import { useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Input,
  Label,
  Col,
  Row,
  Button,
  DatePicker,
  RadioButton,
  Loader,
  TextAreaWithLabel,
  ErrorText,
} from "@d-lighted/design-system";
import { PrimaryLightBackground } from "../../../components/utils/Helper";
import StyledRow from "../modules/StyledRow";
import BackButton from "../modules/BackButton";
import Title from "../modules/Title";
import messages from "../i18n/MultiGuest";
import { useValidation } from "../../../utils/validation";
import { useMultiguestCalendar } from '../hooks/useMultiguestCalendar';
import { moment } from '../../../components/preview/momentUtils';
import { Center } from '../../../components/preview/Helper';
import Tooltip from '../../../components/elements/Tooltip';
import MultiguestUrlCreatedModal from '../modules/MultiguestUrlCreatedModal';
import { useGet } from "../../../utils/api";
import { actions } from "../../../redux/actions";
import { useSelector } from "react-redux";

const Container = styled.div`
  margin: 41px;
  padding: 10px 0 30px;
  border-radius: 6px;
  opacity: 1;
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

function MultiGuest(props) {
  const { useNotify, intl, initialize, history} = props
  const params = useParams();
  useGet(calendarSettingRequest(params.uid));
  const notify = useNotify(intl);
  const { required, inputLength } = useValidation(intl);
  const [multiguestUrl, setMultiguestUrl] = useState('')
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [calendarNode, setCalendarNode] = useState(null);
  const [calendarDisclaimer, setCalendarDisclaimer] = useState(false);

  const { data } = useSelector((state) => state.calendarSetting);
  const { createMultiguestCalendar } = useMultiguestCalendar(notify)

  useEffect(() => {
    initialize({
      description: intl.formatMessage({...messages.descriptionPlaceholder}),
      completedMessageWebpage: intl.formatMessage({...messages.completedMessageWebpagePlaceholder}),
      duration: 30,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is because useRef won't trigger useEffect when called from DOM elements
  const calendarRef = useCallback(node => {
    setCalendarNode(node?.querySelector('.react-calendar__month-view__days'))
  }, []);

  useEffect(() => {
    if(calendarNode != null) {
      const callback = e => {
        if (e.target.closest('button').getAttribute('disabled') != null) {
          calendarNode.removeEventListener('click', callback);
          setCalendarDisclaimer(true);
        }
      }

      calendarNode.addEventListener('click', callback, false);

      return () => {
        calendarNode.removeEventListener('click', callback);
      }
    }
  }, [calendarNode])

  const onSubmit = (values) => {
    setLoading(true)
    createMultiguestCalendar(values).then(response => {
      if(response.status !== 400) {
        const url = `${process.env.REACT_APP_BOOK_ME_ENDPOINT}/${response.calendarUrl}`;
        setMultiguestUrl(url)
      }
      setLoading(false)
    })
  }

  const onChange = (value) => {
    setDateRange(value)
    props.dispatch(change("MultiGuest", "dateRange", value.map(v => v.toDateString())));
  }

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <PrimaryLightBackground>
      <StyledRow onClick={() => { history.goBack() }}>
        <BackButton />
      </StyledRow>
      <StyledRow>
        <Title title={data?.title} />
      </StyledRow>
      <Container>
        <Row bg="white" pl="35px" pt="25px" borderRadius="10px">
          <Col xs>
            <Row mb="14px">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.title} />
                </Label>
              </Col>
            </Row>
            <Row mb="25px" width="400px">
              <Col xs>
                <Field
                  name="title"
                  component={Input}
                  validate={[required]}
                  placeholder={intl.formatMessage({...messages.titlePlaceholder})}
                />
              </Col>
            </Row>
            <Row mb="25px" width="400px">
              <Col xs>
                <Field
                  id="description"
                  name="description"
                  validate={[inputLength]}
                  component={TextAreaWithLabel}
                  labelLeft={<FormattedMessage {...messages.description} />}
                  placeholder={intl.formatMessage({...messages.descriptionPlaceholder})}
                />
              </Col>
            </Row>
            <Row mb="14px">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.durations} />
                  <Tooltip
                    message={intl.formatMessage(
                      { ...messages.durationsTooltip },
                      {
                        br: <br />,
                        a: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://scheduling.help.receptionist.jp/how-to-create-pages/#time2"
                          >
                            {chunks}
                          </a>
                        ),
                      }
                    )}
                    width="auto"
                    top={2}
                    left={400}
                  />
                </Label>
              </Col>
            </Row>
            <Row mb="25px" width="400px">
              <Col>
                <Field
                  id="timeFrame"
                  name="duration"
                  trueFor={30}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.d30} />
                </Field>
              </Col>
              <Col >
                <Field
                  id="timeFrame"
                  name="duration"
                  trueFor={60}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.d60} />
                </Field>
              </Col>
              <Col>
                <Field
                  id="timeFrame"
                  name="duration"
                  trueFor={90}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.d90} />
                </Field>
              </Col>
              <Col>
                <Field
                  id="timeFrame"
                  name="duration"
                  trueFor={120}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.d120} />
                </Field>
              </Col>
              <Col>
                <Field
                  id="timeFrame"
                  name="duration"
                  trueFor={180}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.d180} />
                </Field>
              </Col>
            </Row>
            <Row mb="25px">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.range} />
                  <Tooltip
                    message={intl.formatMessage(
                      { ...messages.rangeTooltip },
                      {
                        br: <br />,
                      }
                    )}
                    width="auto"
                    top={2}
                    left={400}
                  />
                </Label>
              </Col>
            </Row>
            <Row mb="45px">
              <Col xs>
                <Row>
                  <Col xs ref={calendarRef}>
                    <DatePicker
                      selectPast={false}
                      showActionButton={false}
                      selectRange={true}
                      highlightWeek={false}
                      maxDate={moment().add(30, "days").toDate()}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
                {calendarDisclaimer && (
                  <Row mt="16px">
                    <Col xs ref={calendarRef}>
                      <ErrorText>
                        <FormattedMessage {...messages.rangeError} />
                      </ErrorText>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row mb="25px" width="400px">
              <Col xs>
                <Field
                  id="completedMessageWebpage"
                  name="completedMessageWebpage"
                  validate={[inputLength]}
                  component={TextAreaWithLabel}
                  labelLeft={(
                    <>
                      {intl.formatMessage({...messages.completedMessageWebpage})}
                      <Tooltip
                        message={intl.formatMessage({ ...messages.completeMessageWebpageTooltip },
                          {
                            br: <br />,
                          }
                        )}
                        width="auto"
                        top={2}
                        left={400}
                      />
                    </>
                  )}
                  placeholder={intl.formatMessage({...messages.completedMessageWebpagePlaceholder})}
                  height="216px"
                />
              </Col>
            </Row>
            <Row mb="20px">
              <Col xs>
                <Button
                  width="400px"
                  disabled={dateRange.length === 0 || props.pristine || props.submitting}
                  onClick={props.handleSubmit((values) => onSubmit(values))}
                >
                  <FormattedMessage {...messages.create} />
                </Button>
              </Col>
            </Row>
          </Col>
          {multiguestUrl &&
            <MultiguestUrlCreatedModal
              isOpen
              multiguestUrl={multiguestUrl}
              returnToDashboard={() => {history.goBack()}}
            />
          }
        </Row>
      </Container>
    </PrimaryLightBackground>
  );
};

export default reduxForm({
  form: "MultiGuest"
})(injectIntl(MultiGuest));
